import GtbButton from "../../GtbButton";
import useDialog from "../../dialog/useDialog";
import DialogHeader from "../../dialog/DialogHeader";
import DialogFooter from "../../dialog/DialogFooter";
import { useMutation } from "../../../hooks/useAxios";
import { useCallback } from "react";
import { idHandlingMode, useMultiSelectStore } from "../../../utils/multiSelectStore";
import { info, warning } from "../../../utils/notification/notification";
import { getValidationErrorMessageViaString, handleResponseError } from "../../../utils/errorHandler";
import { WithBulkActionProps } from "./withBulkActions";
import { buildURL } from "../component/useQueryBuilder";
import useGtbTranslation, { I18nKey } from "../../../i18n/useGtbTranslation";
import DialogContent from "../../dialog/DialogContent";
import { DialogYesNoOptions } from "../../dialog/DialogOptions";
import { Entity } from "../../../utils/typeUtils";
import useLoadingDialog from "../../dialog/useLoadingDialog";

interface UseBulkDeleteProps<ItemType extends Entity> {
    queryCommands: WithBulkActionProps<ItemType>["queryCommands"];
    total: WithBulkActionProps<ItemType>["total"];
    query: WithBulkActionProps<ItemType>["query"];
}

function useBulkDeleteFailedDialog() {
    const translation = useGtbTranslation();
    const { showDialog, closeDialog } = useDialog();

    return (failedDeletions: FailedDeletion[]) => {
        const parseErrorCode = (failure: FailedDeletion) =>
            translation(getValidationErrorMessageViaString(failure.code));

        showDialog({
            header: <DialogHeader title={"components.dialog.warning_title"} variant={"warning"} />,
            content: (
                <DialogContent>
                    <p>{translation("components.grid.someRecordsNotDeleted_dialogText") + ":"}</p>
                    {failedDeletions.map((failure) => (
                        <p key={failure.id}>
                            <b>
                                {failure.systemId !== failure.name && <i>({failure.systemId}) </i>}
                                {failure.name}:
                            </b>
                            <br />
                            {parseErrorCode(failure)}
                        </p>
                    ))}
                </DialogContent>
            ),
            footer: (
                <DialogFooter>
                    <GtbButton variant="secondary" onClick={closeDialog}>
                        {translation("components.dialog.ok_button")}
                    </GtbButton>
                </DialogFooter>
            ),
        });
    };
}

function useBulkDeleteConfirmationDialog() {
    const translation = useGtbTranslation();
    const { showDialog: showConfirmationDialog, closeDialog } = useDialog();

    return {
        showDialog: (totalElements: number | null, onConfirm: Function) =>
            showConfirmationDialog({
                header: (
                    <DialogHeader title={"components.grid.deleteSelectedRecords_dialogTitle"} variant={"warning"} />
                ),
                content: (
                    <DialogContent>
                        <p>
                            {translation({
                                key: "components.grid.areYouSure_dialogText",
                                options: { totalElements: totalElements },
                            })}
                        </p>
                        <p>{translation("components.grid.cannotBeUndone_dialogText")}</p>
                    </DialogContent>
                ),
                footer: (
                    <DialogFooter>
                        <DialogYesNoOptions onYes={() => onConfirm()} onNo={closeDialog} />
                    </DialogFooter>
                ),
            }),
        closeDialog,
    };
}

export default function useBulkDelete<ItemType extends Entity>({
    queryCommands,
    total,
    query,
}: UseBulkDeleteProps<ItemType>) {
    const translation = useGtbTranslation();
    const { selectedItems, allSelectedState, isBlacklist } = useMultiSelectStore();
    const { showDialog: showConfirmationDialog, closeDialog } = useBulkDeleteConfirmationDialog();
    const { showDialog: showLoadingDialog } = useLoadingDialog();
    const showBulkDeleteFailedDialog = useBulkDeleteFailedDialog();

    const { runQuery } = useMutation({
        method: "delete",
        url: buildURL(query.url.replace("/certificate-service/", "/certificate-service-wo-timeout/"), query.parameter),
    });

    return useCallback(() => {
        const allItemsSelected = allSelectedState === "all";
        const itemCount = selectedItems?.length;

        function handleSuccess(response: BulkDeleteResultResponse) {
            closeDialog();
            if (response.successfullyDeleted > 0) {
                info(
                    translation({
                        key: "components.grid.recordsHaveBeenDeleted_toast",
                        options: { successfullyDeleted: response.successfullyDeleted },
                    })
                );
            }

            const failedDeletions = response.failedDeletions;
            if (failedDeletions.length > 0) {
                showBulkDeleteFailedDialog(failedDeletions);
            }
        }

        function handleError() {
            handleResponseError();
            closeDialog();
        }

        const performDeletion = () => {
            showLoadingDialog("components.grid.deleteSelectedRecords_dialogTitle");
            runQuery({
                body: {
                    filter: query.parameter.filter,
                    ids: selectedItems,
                    idHandlingMode: isBlacklist ? idHandlingMode.EXCLUDE : idHandlingMode.INCLUDE,
                },
            })
                .then(handleSuccess)
                .catch(handleError)
                .finally(() => queryCommands?.refresh());
        };

        if (allSelectedState === "none" || (allSelectedState !== "all" && itemCount === 0)) {
            warning(translation("error.grid.noRecordsMarkedForDeletion_message"));
        } else {
            const totalElements = calculateNumTotalElements(allItemsSelected, isBlacklist, total, itemCount);
            showConfirmationDialog(totalElements, performDeletion);
        }
    }, [
        allSelectedState,
        closeDialog,
        isBlacklist,
        query.parameter.filter,
        queryCommands,
        runQuery,
        selectedItems,
        showBulkDeleteFailedDialog,
        showConfirmationDialog,
        showLoadingDialog,
        total,
        translation,
    ]);
}

function calculateNumTotalElements(allItemsSelected: boolean, isBlacklist: boolean, total: number, itemCount: number) {
    if (isBlacklist) {
        return total - itemCount;
    } else {
        return allItemsSelected ? total : itemCount;
    }
}

interface BulkDeleteResultResponse {
    successfullyDeleted: number;
    failedDeletions: FailedDeletion[];
}

interface FailedDeletion {
    id: string;
    systemId: string;
    name: string;
    code: I18nKey & string;
}
