import ViewContainer from "../components/ViewContainer";
import { useQuery } from "../hooks/useAxios";
import LoadingSpinner from "../components/LoadingSpinner";
import "./systemparameterview.css";
import Card from "../components/card/Card";
import CardHeader from "../components/card/CardHeader";
import { env } from "../env";
import { useChangeNavigationContext } from "../components/breadcrumb/useNavigationContext";
import { useEffect } from "react";

export default function SystemParameterView() {
    const { pushNavigationContext } = useChangeNavigationContext();

    const { data: systemParameters, isLoading } = useQuery<SystemParameters>({
        url: "/certificate-service/system-parameters",
        enabled: true,
    });

    useEffect(() => {
        pushNavigationContext("systemParameters.header");
    }, [pushNavigationContext]);

    return (
        <ViewContainer>
            {isLoading && <LoadingSpinner />}
            {systemParameters && (
                <Card className={"parameter-card"}>
                    <CardHeader title="systemParameters.header" />
                    <dl>
                        <dt>base_url</dt>
                        <dd>{systemParameters["base_url"]}</dd>
                        <dt>beta_features_enabled</dt>
                        <dd>{systemParameters["beta_features_enabled"]}</dd>
                        <dt>restricted_languages</dt>
                        <dd>{systemParameters["restricted_languages"]}</dd>
                        <dt>automatic_default_test_stamp</dt>
                        <dd>{systemParameters["automatic_default_test_stamp"]}</dd>
                        <dt>enable_check_for_digital_signature_in_sc_document</dt>
                        <dd>{systemParameters["enable_check_for_digital_signature_in_sc_document"]}</dd>

                        <dt>max_login_attempts</dt>
                        <dd>{systemParameters["max_login_attempts"]}</dd>
                        <dt>jwt_expiration_in_minutes</dt>
                        <dd>{systemParameters["jwt_expiration_in_minutes"]}</dd>
                        <dt>jwt_refresh_token_validity_in_hours</dt>
                        <dd>{systemParameters["jwt_refresh_token_validity_in_hours"]}</dd>
                        <dt>timeout_period_seconds</dt>
                        <dd>{env.REACT_APP_TIMEOUT_PERIOD_SECONDS}</dd>
                        <dt>timeout_warning_period_seconds</dt>
                        <dd>{env.REACT_APP_TIMEOUT_WARNING_PERIOD_SECONDS}</dd>
                        <dt>otp_expiration_in_minutes</dt>
                        <dd>{systemParameters["otp_expiration_in_minutes"]}</dd>
                        <dt>password_reset_valid_time</dt>
                        <dd>{systemParameters["password_reset_valid_time"]}</dd>
                        <dt>password_min_length</dt>
                        <dd>{systemParameters["password_min_length"]}</dd>

                        <dt>no_processing_category_code</dt>
                        <dd>{systemParameters["no_processing_category_code"]}</dd>
                        <dt>certification_relation_confirmation_expiry_in_days</dt>
                        <dd>{systemParameters["certification_relation_confirmation_expiry_in_days"]}</dd>
                        <dt>subcontracting_relation_confirmation_expiry_in_days</dt>
                        <dd>{systemParameters["subcontracting_relation_confirmation_expiry_in_days"]}</dd>
                        <dt>buying_relation_confirmation_expiry_in_days</dt>
                        <dd>{systemParameters["buying_relation_confirmation_expiry_in_days"]}</dd>
                        <dt>sc_validity_restriction_in_days</dt>
                        <dd>{systemParameters["sc_validity_restriction_in_days"]}</dd>
                        <dt>max_file_size_kb</dt>
                        <dd>{env.REACT_APP_MAX_FILE_SIZE_KB}</dd>
                        <dt>acceptable_image_types</dt>
                        <dd>{systemParameters["acceptable_image_types"]}</dd>
                        <dt>acceptable_pdf_type</dt>
                        <dd>{systemParameters["acceptable_pdf_type"]}</dd>
                        <dt>max_chars_for_tiles</dt>
                        <dd>{systemParameters["max_chars_for_tiles"]}</dd>
                        <dt>production_capacity_excess</dt>
                        <dd>{systemParameters["production_capacity_excess"]}</dd>
                        <dt>tc_upper_limit_for_shipments</dt>
                        <dd>{systemParameters["tc_upper_limit_for_shipments"]}</dd>
                        <dt>tc_upper_limit_for_products</dt>
                        <dd>{systemParameters["tc_upper_limit_for_products"]}</dd>
                        <dt>maximum_date_for_pre-gtb_tcs</dt>
                        <dd>{systemParameters["maximum_date_for_pre-gtb_tcs"]}</dd>
                        <dt>time_based_mail_job_schedule</dt>
                        <dd>{systemParameters["time_based_mail_job_schedule"]}</dd>

                        <dt>deduplication.similarity_threshold.town</dt>
                        <dd>{systemParameters["deduplication.similarity_threshold.town"]}</dd>
                        <dt>deduplication.similarity_threshold.legal_name</dt>
                        <dd>{systemParameters["deduplication.similarity_threshold.legal_name"]}</dd>
                        <dt>deduplication.similarity_threshold.business_name</dt>
                        <dd>{systemParameters["deduplication.similarity_threshold.business_name"]}</dd>

                        <dt>personal_data.backup_retention_period</dt>
                        <dd>{systemParameters["personal_data.backup_retention_period"]}</dd>
                        <dt>personal_data.expiration_period.accreditation_body</dt>
                        <dd>{systemParameters["personal_data.expiration_period.accreditation_body"]}</dd>
                        <dt>personal_data.expiration_period.authorisation_role</dt>
                        <dd>{systemParameters["personal_data.expiration_period.authorisation_role"]}</dd>
                        <dt>personal_data.expiration_period.by_product_input_combination</dt>
                        <dd>{systemParameters["personal_data.expiration_period.by_product_input_combination"]}</dd>
                        <dt>personal_data.expiration_period.certification_body</dt>
                        <dd>{systemParameters["personal_data.expiration_period.certification_body"]}</dd>
                        <dt>personal_data.expiration_period.country</dt>
                        <dd>{systemParameters["personal_data.expiration_period.country"]}</dd>
                        <dt>personal_data.expiration_period.dynamic_user_group</dt>
                        <dd>{systemParameters["personal_data.expiration_period.dynamic_user_group"]}</dd>
                        <dt>personal_data.expiration_period.material_content_rule</dt>
                        <dd>{systemParameters["personal_data.expiration_period.material_content_rule"]}</dd>
                        <dt>personal_data.expiration_period.organic_material_producer</dt>
                        <dd>{systemParameters["personal_data.expiration_period.organic_material_producer"]}</dd>
                        <dt>personal_data.expiration_period.process_category</dt>
                        <dd>{systemParameters["personal_data.expiration_period.process_category"]}</dd>
                        <dt>personal_data.expiration_period.product_category</dt>
                        <dd>{systemParameters["personal_data.expiration_period.product_category"]}</dd>
                        <dt>personal_data.expiration_period.product_conversion</dt>
                        <dd>{systemParameters["personal_data.expiration_period.product_conversion"]}</dd>
                        <dt>personal_data.expiration_period.product_detail</dt>
                        <dd>{systemParameters["personal_data.expiration_period.product_detail"]}</dd>
                        <dt>personal_data.expiration_period.raw_material</dt>
                        <dd>{systemParameters["personal_data.expiration_period.raw_material"]}</dd>
                        <dt>personal_data.expiration_period.standard</dt>
                        <dd>{systemParameters["personal_data.expiration_period.standard"]}</dd>
                        <dt>personal_data.expiration_period.standard_body</dt>
                        <dd>{systemParameters["personal_data.expiration_period.standard_body"]}</dd>
                        <dt>personal_data.expiration_period.supply_chain_operator</dt>
                        <dd>{systemParameters["personal_data.expiration_period.supply_chain_operator"]}</dd>
                        <dt>personal_data.expiration_period.scope_certificate</dt>
                        <dd>{systemParameters["personal_data.expiration_period.scope_certificate"]}</dd>
                        <dt>personal_data.expiration_period.pre_gtb_tc</dt>
                        <dd>{systemParameters["personal_data.expiration_period.pre_gtb_tc"]}</dd>
                        <dt>personal_data.expiration_period.transaction_certificate</dt>
                        <dd>{systemParameters["personal_data.expiration_period.transaction_certificate"]}</dd>
                        <dt>personal_data.expiration_period.user</dt>
                        <dd>{systemParameters["personal_data.expiration_period.user"]}</dd>
                        <dt>personal_data.expiration_period.mail_template</dt>
                        <dd>{systemParameters["personal_data.expiration_period.mail_template"]}</dd>
                        <dt>personal_data.expiration_period.time_based_mail</dt>
                        <dd>{systemParameters["personal_data.expiration_period.time_based_mail"]}</dd>
                    </dl>
                </Card>
            )}
        </ViewContainer>
    );
}

interface SystemParameters {
    base_url: string;
    beta_features_enabled: string;
    restricted_languages: string;
    automatic_default_test_stamp: string;
    enable_check_for_digital_signature_in_sc_document: string;

    max_login_attempts: string;
    jwt_expiration_in_minutes: string;
    jwt_refresh_token_validity_in_hours: string;
    otp_expiration_in_minutes: string;
    password_reset_valid_time: string;
    password_min_length: string;

    no_processing_category_code: string;
    certification_relation_confirmation_expiry_in_days: string;
    subcontracting_relation_confirmation_expiry_in_days: string;
    buying_relation_confirmation_expiry_in_days: string;
    sc_validity_restriction_in_days: string;
    acceptable_image_types: string;
    acceptable_pdf_type: string;
    max_chars_for_tiles: string;

    "deduplication.similarity_threshold.town": string;
    "deduplication.similarity_threshold.legal_name": string;
    "deduplication.similarity_threshold.business_name": string;

    "personal_data.backup_retention_period": string;
    "personal_data.expiration_period.accreditation_body": string;
    "personal_data.expiration_period.authorisation_role": string;
    "personal_data.expiration_period.by_product_input_combination": string;
    "personal_data.expiration_period.certification_body": string;
    "personal_data.expiration_period.country": string;
    "personal_data.expiration_period.dynamic_user_group": string;
    "personal_data.expiration_period.material_content_rule": string;
    "personal_data.expiration_period.organic_material_producer": string;
    "personal_data.expiration_period.process_category": string;
    "personal_data.expiration_period.product_category": string;
    "personal_data.expiration_period.product_conversion": string;
    "personal_data.expiration_period.product_detail": string;
    "personal_data.expiration_period.raw_material": string;
    "personal_data.expiration_period.standard": string;
    "personal_data.expiration_period.standard_body": string;
    "personal_data.expiration_period.supply_chain_operator": string;
    "personal_data.expiration_period.scope_certificate": string;
    "personal_data.expiration_period.pre_gtb_tc": string;
    "personal_data.expiration_period.transaction_certificate": string;
    "personal_data.expiration_period.user": string;
    "personal_data.expiration_period.mail_template": string;
    "personal_data.expiration_period.time_based_mail": string;

    production_capacity_excess: string;

    tc_upper_limit_for_shipments: string;
    tc_upper_limit_for_products: string;

    "maximum_date_for_pre-gtb_tcs": Date;

    time_based_mail_job_schedule: string;
}
